import React from "react";

const tel = {
  value: "8582645748",
  display: "(858)264-5748",
};

const email = {
  value: "ronnie@rhomeloans.com",
};

const TopLinkBar = (props) => (
  <div className="top-bar">
    <div className={"container"} style={{ height: "2rem" }}>
      <div className={"level is-mobile top-bar-container"}>
        <div className={"level-left"} />
        <div className={"level-right"}>
          <div className={"level-item has-text-centered top-bar-item"}>
            <a className={"subtitle is-7"} href={`tel:${tel.value}`}>
              {tel.display}
            </a>
          </div>
          <div className={"level-item has-text-centered top-bar-item"}>
            <a className={"subtitle is-7"} href={`mailto:${email.value}`}>
              {email.value}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default TopLinkBar;
