import React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.png";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
// import twitter from "../img/social/twitter.svg";
// import vimeo from "../img/social/vimeo.svg";
import equal from "../../static/img/EHL-Logo.png";

const Footer = class extends React.Component {
  render() {
    const isContact =
      typeof window !== "undefined"
        ? window.location.pathname === "/contact"
        : false;
    return (
      <>
        {isContact ? null : (
          <section>
            <div className="has-background-success" style={{ height: "8rem" }}>
              <div className="container" style={{ height: "100%" }}>
                <div
                  className="columns is-centered is-vcentered"
                  style={{ height: "100%" }}
                >
                  <div className="column is-narrow has-text-centered">
                    <h3 className="title is-3 has-text-white">Questions?</h3>
                  </div>
                  <div className="column is-narrow has-text-centered">
                    <Link
                      to="/contact"
                      className="button is-rounded is-outlined is-white fa-bold"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <footer className="footer has-background-black has-text-white-ter">
          <div className="content has-text-centered">
            <img
              src={logo}
              alt="Robison Home Loans"
              style={{ maxWidth: "14em", maxHeight: "10em" }}
            />
          </div>
          <div className="content has-text-centered has-background-black has-text-white-ter">
            <div className="container has-background-black has-text-white-ter">
              <div style={{ maxWidth: "100vw" }} className="columns">
                <div className="column is-4">
                  <section className="menu">
                    <ul className="menu-list">
                      <li>
                        <Link to="/" className="navbar-item">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar-item" to="/about">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="navbar-item"
                          to="/process-of-a-mortgage"
                        >
                          Get Started
                        </Link>
                      </li>
                    </ul>
                  </section>
                </div>
                <div className="column is-4">
                  <section>
                    <ul className="menu-list">
                      <li>
                        <Link className="navbar-item" to="/blog">
                          Latest Stories
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar-item" to="/contact">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </section>
                </div>
                <div className="column is-4 social">
                  <a
                    title="facebook"
                    href="https://www.facebook.com/RobisonHomeLoans/"
                  >
                    <img
                      src={facebook}
                      alt="Facebook"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a>
                  {/*<a title="twitter" href="https://twitter.com">*/}
                  {/*  <img*/}
                  {/*    className="fas fa-lg"*/}
                  {/*    src={twitter}*/}
                  {/*    alt="Twitter"*/}
                  {/*    style={{ width: "1em", height: "1em" }}*/}
                  {/*  />*/}
                  {/*</a>*/}
                  <a
                    title="instagram"
                    href="https://www.instagram.com/robison_home_loans/"
                  >
                    <img
                      src={instagram}
                      alt="Instagram"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a>
                  {/*<a title="vimeo" href="https://vimeo.com">*/}
                  {/*  <img*/}
                  {/*    src={vimeo}*/}
                  {/*    alt="Vimeo"*/}
                  {/*    style={{ width: "1em", height: "1em" }}*/}
                  {/*  />*/}
                  {/*</a>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="content has-background-black has-text-white-ter">
            <div className="container has-background-black has-text-white-ter">
              <div style={{ maxWidth: "100vw" }} className="columns">
                <div className="column is-half">
                  <h3 className="title is-4 has-text-white-ter">Disclaimer</h3>
                  <p className="has-test-white">
                    All information is deemed reliable but not guaranteed. All
                    properties are subject to prior sale, charge or withdrawal.
                    Neither listing broker(s) or information provider(s) shall
                    be responsible for any typographical errors, misinformation,
                    misprints and shall be held totally harmless. Listing(s)
                    information is provided for consumers personal,
                    non-commercial use and may not be used for any purpose other
                    than to identify prospective properties consumers may be
                    interested in purchasing.
                  </p>
                </div>
                <div className="column is-one-quarter">
                  <section className="menu">
                    <h3 className="title is-4 has-text-white-ter">Contact</h3>
                    <ul className="menu-list">
                      <li>
                        <Link to={"/about"} className="navbar-item">
                          Ronnie Robison
                        </Link>
                      </li>
                      <li>
                        <Link to={"/about"} className="navbar-item">
                          NMLS #393335
                        </Link>
                      </li>
                      <li>
                        <a
                          className="navbar-item"
                          href="mailto:Ronnie@rhomeloans.com"
                        >
                          Ronnie@rhomeloans.com
                        </a>
                      </li>
                      <li>
                        <a className="navbar-item" href="tel:8582645748">
                          (858) 264-5748
                        </a>
                      </li>
                      <li>
                        <a
                          className="navbar-item"
                          href="https://www.google.com/maps/place/508+W+Mission+Ave+%23200,+Escondido,+CA+92025/@33.1296962,-117.0950654,17z/data=!3m1!4b1!4m5!3m4!1s0x80dbf4bd1e35a209:0xee40671129db87f8!8m2!3d33.1296962!4d-117.0928767"
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          508 W Mission Ave #200 Escondido, CA 92025
                        </a>
                      </li>
                    </ul>
                  </section>
                </div>
                <div className="column is-one-quarter">
                  <img src={equal} alt={"Equal Housing Lender"} />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
};

export default Footer;
