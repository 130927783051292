import React from "react";
import { Link } from "gatsby";
import logoWhite from "../img/logo-white.png";
import logo from "../img/logo.png";

const TransparentNavPagePaths = [
  "/",
  "/process-of-a-mortgage",
  "/types-of-a-mortgage",
  "/refinancing",
  "/understanding-your-credit",
  "/mortgage-glossary-of-terms",
  "/blog",
  "/privacy-policy",
  "/consumer-grievance-policy",
  "/apply-now",
];

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
      scrollPosition: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      ...this.state,
      scrollPosition: scrolled,
    });
  };

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    const isScrolled = this.state.scrollPosition > 0;
    const isHome =
      typeof window !== "undefined"
        ? TransparentNavPagePaths.includes(window.location.pathname)
        : false;
    const isWhiteNav =
      (isScrolled && isHome) || !isHome || this.state.navBarActiveClass;
    return (
      <nav
        className={`navbar ${
          isWhiteNav ? "is-white" : "is-transparent"
        } is-fixed-top`}
        role="navigation"
        aria-label="main-navigation"
        style={{ top: 30, height: isWhiteNav ? "5rem" : "7rem" }}
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item is-large" title="Logo">
              <img
                src={isWhiteNav ? logo : logoWhite}
                alt="Robison Home Loans"
                style={{ maxHeight: isWhiteNav ? "4rem" : "7rem" }}
              />
            </Link>
            {/* Hamburger menu */}
            <button
              className={`navbar-burger burger has-background-white ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link
                className="navbar-item"
                style={isWhiteNav ? {} : { color: "#fff", fontWeight: 700 }}
                to="/about"
              >
                About
              </Link>
              <div className={`navbar-item has-dropdown is-hoverable`}>
                <Link
                  className={`navbar-link`}
                  style={isWhiteNav ? {} : { color: "#fff", fontWeight: 700 }}
                  to="/process-of-a-mortgage"
                >
                  Get Started
                </Link>
                <div
                  className="navbar-dropdown"
                  style={isWhiteNav ? {} : { marginTop: "-2rem" }}
                >
                  <Link to="/apply-now" className="navbar-item">
                    Apply Now
                  </Link>
                  <Link className={`navbar-item`} to="/process-of-a-mortgage">
                    Process Of A Mortgage
                  </Link>
                  <Link className={`navbar-item`} to="/types-of-a-mortgage">
                    Types Of A Mortgage
                  </Link>
                  <Link className={`navbar-item`} to="/refinancing">
                    Refinancing
                  </Link>
                  <Link
                    className={`navbar-item`}
                    to="/understanding-your-credit"
                  >
                    Understanding Your Credit
                  </Link>
                  <Link
                    className={`navbar-item`}
                    to="/mortgage-glossary-of-terms"
                  >
                    Mortgage Glossary Of Terms
                  </Link>
                </div>
              </div>

              <Link
                className="navbar-item"
                style={isWhiteNav ? {} : { color: "#fff", fontWeight: 700 }}
                to="/blog"
              >
                Blog
              </Link>
              <div className={`navbar-item has-dropdown is-hoverable`}>
                <Link
                  className="navbar-link"
                  style={isWhiteNav ? {} : { color: "#fff", fontWeight: 700 }}
                  to="/contact"
                >
                  Contact
                </Link>
                <div
                  className="navbar-dropdown"
                  style={isWhiteNav ? {} : { marginTop: "-2rem" }}
                >
                  <Link className="navbar-item" to="/contact">
                    Contact Us
                  </Link>
                  <Link className="navbar-item" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                  <Link className="navbar-item" to="/consumer-grievance-policy">
                    Consumer Grievance Policy
                  </Link>
                </div>
              </div>
              <Link to="/apply-now" className="navbar-item">
                <button
                  className={"button is-rounded is-primary"}
                  style={{ fontWeight: 700 }}
                >
                  Apply Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
